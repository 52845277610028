import React, { useState } from 'react';
import '../css/styles.css'; 
import contactImg from '../assets/contact.png'

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Replace 'YOUR_ACCESS_KEY' with your actual access key from Web3Forms
    const accessKey = 'YOUR_ACCESS_KEY';

    const response = await fetch('https://api.web3forms.com/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        access_key: accessKey,
        ...formData
      })
    });

    if (response.ok) {
      setIsSubmitted(true);
      setFormData({ name: '', phone: '', email: '', message: '' });
    } else {
      alert('Something went wrong. Please try again.');
    }
  };

  return (
    <section className="contact-section">
      <h2>Contact Us</h2>
      <p>Contact us with any questions or requests regarding our extensive range of services. We will be happy to meet you in person at one of our offices in Dubai or Bratislava.</p>
      <div className='contact-area'>
      {isSubmitted ? (
        <p>Thank you for your message. We will get back to you soon!</p>
      ) : (
        <form onSubmit={handleSubmit} className="contact-form">
          <label>
            Name
            <input 
              type="text" 
              name="name" 
              value={formData.name} 
              onChange={handleChange} 
              required 
            />
          </label>
          <label>
            Phone Number
            <input 
              type="text" 
              name="phone" 
              value={formData.phone} 
              onChange={handleChange} 
              required 
            />
          </label>
          <label>
            E-mail
            <input 
              type="email" 
              name="email" 
              value={formData.email} 
              onChange={handleChange} 
              required 
            />
          </label>
          <label>
            Message
            <textarea 
              name="message" 
              value={formData.message} 
              onChange={handleChange} 
              required 
            />
          </label>
        </form>
      )}
      <div className="contact-image">
        <img src={contactImg} alt="Contact" />
      </div>
      </div>
      <button type="submit">SEND EMAIL →</button>
    </section>
  );
};

export default ContactForm;
