import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { propertiesData } from './Data';
import '../css/styles.css'; 

const PropertiesPage = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const propertiesPerPage = 3; // Number of properties to display per page
  const navigate = useNavigate();

  // Calculate the total number of pages
  const totalPages = Math.ceil(propertiesData.length / propertiesPerPage);

  // Determine the properties to display on the current page
  const startIndex = currentPage * propertiesPerPage;
  const currentProperties = propertiesData.slice(startIndex, startIndex + propertiesPerPage);

  // Navigation functions for pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => (prevPage - 1 + totalPages) % totalPages);
  };

  // Function to navigate to the property detail page
  const goToPropertyPage = (link) => {
    navigate(link);
  };

  return (
    <div className="properties-page">
      <div className="properties-page-container">
        <h2>Our <span>Properties</span></h2>
        <div className="properties-carousel">
          {currentProperties.map((property) => (
            <div key={property.id} className="property-page-card">
              <img src={property.image} alt={property.title} />
              <div className="property-page-text">
                <h3>{property.title}</h3>
                <p>{property.short_desc}</p>
                <button onClick={() => goToPropertyPage(property.link)}>View Property</button>
              </div>
            </div>
          ))}
        </div>
        <div className="carousel-controls">
          <button onClick={prevPage}>←</button>
          <span>{`${currentPage + 1} / ${totalPages}`}</span>
          <button onClick={nextPage}>→</button>
        </div>
      </div>
    </div>
  );
};

export default PropertiesPage;
