import React, { useState } from 'react';
import '../css/styles.css';

const feedbackData = [
  {
    stars: 5,
    text: "Spolupráce byla perfektní. Profesionální přístup a rychlé vyřízení všech formalit. Vřele doporučuji každému, kdo hledá spolehlivé realitní služby.",
    author: "Petr Novák"
  },
  {
    stars: 4,
    text: "Byli jsme velmi spokojeni s nabídkou nemovitostí a ochotou personálu. Některé administrativní procesy mohly být rychlejší, ale jinak skvělá zkušenost.",
    author: "Lenka Horáková"
  },
  {
    stars: 5,
    text: "Tým byl velmi vstřícný a profesionální. Pomohli nám najít perfektní byt a celý proces proběhl hladce. Děkujeme!",
    author: "Michal Dvořák"
  },
  {
    stars: 4,
    text: "Výborné služby a široký výběr nemovitostí. Ocenili bychom větší flexibilitu při jednáních, ale celkově jsme byli spokojeni.",
    author: "Jana Kučerová"
  },
  {
    stars: 5,
    text: "Realitní agentura nám našla ideální dům. Vše proběhlo rychle a bez komplikací. Děkujeme za skvělou práci!",
    author: "Tomáš Svoboda"
  }
];

const Feedback = () => {
  const [currentFeedbackIndex, setCurrentFeedbackIndex] = useState(0);

  const nextFeedback = () => {
    setCurrentFeedbackIndex((prevIndex) => (prevIndex + 3) % feedbackData.length);
  };

  const prevFeedback = () => {
    setCurrentFeedbackIndex((prevIndex) => (prevIndex - 1 + feedbackData.length) % feedbackData.length);
  };

  const renderStars = (stars) => {
    return Array.from({ length: stars }, (_, index) => (
      <span key={index}>★</span>
    ));
  };

  // Helper function to handle circular wrapping of the index
  const getWrappedIndex = (index) => {
    return (index + feedbackData.length) % feedbackData.length;
  };

  // Display up to 3 feedback items at a time
  const displayedFeedback = [];
  for (let i = 0; i < 3; i++) {
    const feedbackIndex = getWrappedIndex(currentFeedbackIndex + i);
    displayedFeedback.push(feedbackData[feedbackIndex]);
  }

  return (
    <section className="feedback">
    <div className="feedback-container">
      <h2>Feedback</h2>
      <div className="feedback-carousel">
        <button className="carousel-button prev-button" onClick={prevFeedback}>←</button>
        <div className="feedback-card-container">
          {displayedFeedback.map((feedback, index) => (
            <div key={index} className="feedback-card">
              <div className="stars">{renderStars(feedback.stars)}</div>
              <p>{feedback.text}</p>
              <p><strong>{feedback.author}</strong></p>
            </div>
          ))}
        </div>
        <button className="carousel-button next-button" onClick={nextFeedback}>→</button>
      </div>
    </div>
  </section>

  );
};

export default Feedback;
