import '../css/styles.css'; 
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Property1 from '../assets/service/house-1353389_1280.jpg';
import Property2 from '../assets/service/house-8571836_1280.jpg';
import Property3 from '../assets/service/real-estate-6688945_1280.jpg';

const services = [
  {
    id: 1,
    image: Property1,
    title: 'CORE SERVICES',
    description: '– BUYING & SELLING RESIDENTIAL PROPERTY\n– PROPERTY RENTAL\n– COMMERCIAL PROPERTIES',
    link: '/properties/location1',
  },
  {
    id: 2,
    image: Property2,
    title: 'REAL ESTATE SERVICES',
    description: '– SHORT-TERM RENTALS\n– RENOVATIONS, INTERIOR DESIGN\n– MAINTANANCE\n– SNAGGING',
    link: '/properties/location2',
  },
  {
    id: 3,
    image: Property3,
    title: 'LEGAL SERVICES',
    description: '– CONVEYANCING SERVICES\n– WILLS\n– POWER OF ATTORNEY\n– VISA',
    link: '/properties/location3',
  },
  {
    id: 4,
    image: Property3,
    title: 'BUSINESS SERVICES',
    description: '– COMPANY ESTABLISHMENT\n– BANK ACCOUNT OPENING\n– FOREIGN EXCHANGE\n– ACCOUNTING SERVICES',
    link: '/properties/location3',
  },
];

const Service = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % services.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + services.length) % services.length);
  };

  const goToPropertyPage = (link) => {
    navigate(link);
  };

  // Helper function to handle circular wrapping of the index
  const getWrappedIndex = (index) => {
    return (index + services.length) % services.length;
  };

  // Display up to 3 services in the carousel and wrap around
  const displayedServices = [];
  for (let i = 0; i < 3; i++) {
    const serviceIndex = getWrappedIndex(currentIndex + i);
    displayedServices.push(services[serviceIndex]);
  }

  return (
    <section className="service-section">
      <h2>Services</h2>
      <div>
        <p>Our boutique approach allows us to take care of our clients from A-Z. We leverage a well-established network of partners and always aim to exceed every client's expectations.</p>
      </div>
      <div className="carousel">
        <button className="carousel-button prev-button" onClick={prevSlide}>←</button>
        <div className="service-card-container">
          {displayedServices.map((service, index) => (
            <div key={service.id} className="service-card">
              <img src={service.image} alt={service.title} />
              <div className="service-info">
                <h3>{service.title}</h3>
                <p>{service.description}</p>
                <button onClick={() => goToPropertyPage(service.link)}>view properties</button>
              </div>
            </div>
          ))}
        </div>
        <button className="carousel-button next-button" onClick={nextSlide}>→</button>
      </div>
    </section>
  );
};

export default Service;
