import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { propertiesData } from './Data'; 
import '../css/styles.css'; 

const Properties = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const itemsToShow = 3;  // Number of items you want to display at once

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % propertiesData.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + propertiesData.length) % propertiesData.length);
  };

  const goToPropertyPage = (link) => {
    navigate(link);
  };

  const getWrappedIndex = (index) => {
    return (index + propertiesData.length) % propertiesData.length;
  };

  const displayedItems = [];
  for (let i = 0; i < itemsToShow; i++) {
    const itemIndex = getWrappedIndex(currentIndex + i);
    displayedItems.push(propertiesData[itemIndex]);
  }

  return (
    <section className="featured-locations">
      <div className="carousel">
        <button className="carousel-button prev-button" onClick={prevSlide}>←</button>
        <div className="location-card-container">
          {displayedItems.map((property) => (
            <div key={property.id} className="location-card">
              <img src={property.image} alt={property.title} />
              <div className="location-info">
                <h3>{property.title}</h3>
                <p>{property.short_desc}</p>
                <button onClick={() => goToPropertyPage(property.link)}>view propertie</button>
              </div>
            </div>
          ))}
        </div>
        <button className="carousel-button next-button" onClick={nextSlide}>→</button>
      </div>
    </section>
  );
};

export default Properties;
