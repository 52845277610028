import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './components/HomePage';
import GalleryPage from './components/GalleryPage';
import PropertiesPage from './components/PropertiesPage';
import CertificationsPage from './components/CertificationsPage';
import ContactPage from './components/ContactPage';
import PropertyDetail from './components/PropertyDetail';
import './css/styles.css';
import AboutUs from './components/AboutUs';
import FeatureDetail from './components/FeatureDetail';

const App = () => {
  return (
    <Router>
      <div className="app">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/gallery" element={<GalleryPage />} />
          <Route path="/certifications" element={<CertificationsPage />} />
          <Route path="/contacts" element={<ContactPage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/properties" element={<PropertiesPage />} />
          <Route path="/properties/:name" element={<PropertyDetail />} />
          <Route path="/features/:name" element={<FeatureDetail />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
