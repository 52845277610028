import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { propertiesData } from './Data';
import '../css/styles.css';

const PropertyDetail = () => {
  const { name } = useParams(); 
  const property = propertiesData.find(p => p.name === name);
  const navigate = useNavigate();

  if (!property) {
    return <div>Property not found</div>;
  }

  const goToPropertiesPage = () => {
    navigate('/properties'); 
  };

  return (
    <div className="property-detail">
      <div className="container">
        <div className="link">
          {/* Use a button instead of an anchor for navigation */}
          <button onClick={goToPropertiesPage} className="back-button">
            Back to Properties
          </button>
        </div>
        <div className="property-detail-content">
          <h1>{property.title}</h1>
          <div>
            <img src={property.image} alt={property.title} />
            <div className="property-info">
              <p>{property.description}</p>
              <p><strong>Location:</strong> {property.location}</p>
              <p><strong>Price:</strong> {property.price}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetail;