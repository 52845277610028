import '../css/styles.css'; // Make sure to create this CSS file
import ContactForm from './ContactForm';

const ContactPage = () => {
  return (

        <div className="contact-page">
            <div className='contact-page-container'>
                <div>
                    <ContactForm />
                    <div>
                        <div className="contact-info">
                            <div className="text">
                                <p><strong>Real Estate Dubai</strong></p>
                                <p>1234 Sheikh Zayed Road, Dubai, UAE</p>
                                <p>P.O. Box 12345</p>
                                <p>Dubai, United Arab Emirates</p>
                            </div>
                            <div className="map">
                            <iframe
                                title="company-location"
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.646248529904!2d55.27078368497494!3d25.20484938389724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f4346cbde97c9%3A0x1b7e91d42b9d715f!2sSheikh%20Zayed%20Rd%2C%20Dubai%2C%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1620896056476!5m2!1sen!2s"
                                width="100%"
                                height="450"
                                style={{ border: 0 }}
                                allowFullScreen=""
                                loading="lazy"
                            />
                            </div>
                        </div>
                    </div>
                </div>
           
            </div>
      </div>
  );
};

export default ContactPage;

