import '../css/styles.css';
import aboutImg from '../assets/about.png';
import privacyImg from '../assets/loan.svg';
import trustImg from '../assets/Union-155.svg';
import expertiseImg from '../assets/Union-157.svg';
import clientImg from '../assets/Union-159.svg';
import globalImg from '../assets/Union-161.svg';
import excelenceImg from '../assets/shield.svg';
import ContactForm from './ContactForm';


const AboutUs = () => {
  return (
    <div className="about-us-page">

      <section className="hero-section">
        <div className="hero-content">
          <h1>We Find Your Dream House</h1>
          <div className="subtext">
            <span>Home</span> / <span>About Us</span>
          </div>
        </div>
      </section>

      <section className="our-story">
        <div className="story-content">
          <h2>Our Story</h2>
          <p>We understand that every step in the property journey is a big decision, and that's why Home is here to guide you every step of the way. From initial consultations to the final handover, our team of professionals works tirelessly to ensure that our clients receive the best service possible.</p>
          <p>With a passion for Real Estate and a keen understanding of our clients' needs, we are dedicated to building unique, sustainable, and beautiful homes. We believe that the foundation of our business is built on transparency, honesty, and a commitment to excellence.</p>
        </div>
      </section>

      <section className="statistics">
        <div className="stat-item">
          <h3>280+</h3>
          <p>Projects Done</p>
        </div>
        <div className="stat-item">
          <h3>99+</h3>
          <p>Companies Trusted</p>
        </div>
        <div className="stat-item">
          <h3>360+</h3>
          <p>Buildings Made</p>
        </div>
      </section>

      <section className="explore-more">
        <div className="explore-image">
          <img src={aboutImg} alt="Explore More About Us" />
        </div>
        <div className="explore-text">
          <h2>Explore more about us</h2>
        </div>
      </section>

      <section className="values-section">
        <h2>Our Values</h2>
        <div className="values-container">
          <div className="value-item">
            <img src={privacyImg} alt="Privacy Icon" />
            <h3>Privacy</h3>
            <p>There’s nothing we value more. Every deal is a promise of discretion, ensuring your matters stay quietly safeguarded.</p>
          </div>

          <div className="value-item">
            <img src={trustImg} alt="Trust Icon" />
            <h3>Trust</h3>
            <p>Your trust is our most cherished asset, nurtured with integrity and consistency. It strengthens with each handshake, each shared vision.</p>
          </div>

          <div className="value-item">
            <img src={expertiseImg} alt="Expertise Icon" />
            <h3>Expertise</h3>
            <p>With a deep understanding of Dubai’s real estate nuances, our expertise acts as a compass, guiding you on your path to success.</p>
          </div>

          <div className="value-item">
            <img src={clientImg} alt="Client Approach Icon" />
            <h3>Client Approach</h3>
            <p>Listening, understanding, and acting. We consistently tailor our services to your needs, ensuring your aspirations become reality.</p>
          </div>

          <div className="value-item">
            <img src={globalImg} alt="Global Network Icon" />
            <h3>Global Network</h3>
            <p>Our local expertise pairs perfectly with global connections, ensuring you access a wide-reaching network of real estate opportunities.</p>
          </div>

          <div className="value-item">
            <img src={excelenceImg} alt="Excellence Icon" />
            <h3>Excellence</h3>
            <p>With every project, every consultation, we strive to not just achieve but redefine the standards of real estate excellence.</p>
          </div>
        </div>
      </section>

      <section className="our-steps">
        <h2>Our Steps</h2>
        <p>From concept to completion, we follow a meticulous process that ensures every detail is considered. Our approach is transparent and efficient, guaranteeing a seamless experience from start to finish.</p>
      </section>

      <ContactForm /> 

    </div>

   
  );
};

export default AboutUs;
