import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/styles.css';
import { IoMdArrowForward } from "react-icons/io";
import landingImageOne from '../assets/architecture-5999913_1280.jpg';
import landingImageTwo from '../assets/Landing-BG.png';
import Property1 from '../assets/Property1.png';
import Property2 from '../assets/Property2.png';
import Property3 from '../assets/Property3.png';
import Property4 from '../assets/Property4.png';
import Property5 from '../assets/Property5.png';
import logoJLL from '../assets/images (1).png';
import logo2 from '../assets/images.png';
import logoNakheel from '../assets/GqdCFWgE_400x400.jpg';
import logoDamac from '../assets/b53e6be036019d78a4c677f892bd90f2.jpg';
import logoEmaar from '../assets/emaar-properties.jpg';
import FeaturedLocations from './FeaturedLocations'; // Import the new component
import Service from './Services';
import ContactForm from './ContactForm';    
import Feedback from './Feedback';
import Properties from './Properties';

const HomePage = () => {
  const slides = [
    {
      image: landingImageOne,
      text: 'INVEST IN DUBAI REAL ESTATE',
      number: '01'
    },
    {
      image: landingImageTwo,
      text: 'FIND YOUR DREAM PROPERTY',
      number: '02'
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useNavigate();

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  const goToProjectsPage = () => {
    navigate('/properties'); 
  };

  const goToContactPage = () => {
    navigate('/contact'); 
  };

  return (
    <div className="home-page">

        <section className="landing-section" style={{ backgroundImage: `url(${slides[currentSlide].image})` }}>
              <div className='landing-text'>
                  <div className='landing-text-left'>
                      <h1>{slides[currentSlide].text}</h1>
                      <div className="slider-controls">
                      <button onClick={prevSlide}>←</button>
                      <span>{slides[currentSlide].number}</span>
                      <button onClick={nextSlide}>→</button>
                      </div>
                      <p>Find your dream property with us.</p>
                  </div>
                  <div className='landing-button'>
                      <button onClick={goToProjectsPage}>VIEW PROPERTIES <IoMdArrowForward /></button>
                  </div>
              </div>
        </section>

        <section class="why-dubai-section">
        <div class="container">
          <h2>Why Dubai?</h2>
          <div class="statistics-container">
            <div class="stat">
              <h3>411</h3>
              <p>AED BILLION IN PROPERTIES PURCHASED</p>
            </div>
            <div class="stat">
              <h3>133,135</h3>
              <p>COMPLETED TRANSACTIONS</p>
            </div>
            <div class="stat">
              <h3>4-10%</h3>
              <p>ROI – RETURN ON INVESTMENT</p>
            </div>
            <div class="stat">
              <h3>0%</h3>
              <p>TAX ON SALE</p>
            </div>
            <div class="stat">
              <h3>17,150,000</h3>
              <p>ANNUAL VISITORS</p>
            </div>
          </div>
          <button class="market-report-btn">MARKET REPORT <span>📄</span></button>
        </div>
      </section>

        <section className="properties-section">
          <h2>Properties</h2>
          <div className='container'>
            <div className="property-card one" onClick={goToProjectsPage}>
              <img src={Property5} alt="Sample Property" />
              <div className="property-info">
                <h1>Dubai Properties</h1>
                <p>View More →</p> 
              </div>
            </div>
            <div className="two">
              <img src={Property1} alt="Property 1" />
            </div>
            <div className="three">
              <img src={Property2} alt="Property 2" />
            </div>
            <div className="four">
              <img src={Property3} alt="Property 3" />
            </div>
            <div className="five">
              <img src={Property4} alt="Property 4" />
            </div>
          </div>
          <div className="properties-buttons">
            <button className="contact-button" onClick={goToContactPage}>CONTACTS</button>
            <button className="all-projects-button" onClick={goToProjectsPage}>ALL PROJECTS <IoMdArrowForward /></button>
          </div>
        </section>

        <Properties />

        <FeaturedLocations /> 

        <section className="focus-mission">
          <h2>Main Focus/Mission Statement</h2>
          <div className="focus-content">
            <div className='focus-text'>
              <h1>1</h1>
              <p>We center our efforts on creating lasting relationships by understanding and meeting the unique needs of each client. Through a combination of industry expertise, market knowledge, and innovative strategies, we aim to provide tailored solutions that ensure success in every real estate venture</p>
            </div>
            <div className='focus-text'>
              <h1>2</h1>
              <p>We are committed to providing exceptional real estate services, guiding our clients through every step of the property journey. With a focus on integrity, trust, and personalized solutions, we strive to exceed expectations and deliver unparalleled value in every transaction</p>
            </div>
          </div>
        </section>

        <Service /> 

        <section className="moto-section" >
          <div className="moto-section-bg">
          <div className='container'>
              <div className="moto-text">
                  <h1>UNLOCK THE DOOR TO LUXURY IN A REAL ESTATE WONDERLAND</h1>
                  <p>Step into a world where luxury meets innovation. Our curated selection of elite properties offers unparalleled elegance, modern sophistication, and timeless beauty. Whether you're seeking a serene beachfront villa or a skyline penthouse, we provide access to the finest real estate experiences that elevate your lifestyle. Let us guide you to your dream home in a realm of limitless possibilities.</p>
              </div>
          </div>
          </div>
        </section>

        <section className="partners">
          <div className='partners-container'>       <h2>Our Partners</h2>
        <div className="partners-logos">
          <div className="logo-item"><img src={logoJLL} alt="Partner 1" /><p>JLL (Jones Lang LaSalle)</p></div>
          <div className="logo-item"><img src={logo2} alt="Partner 2" /><p>Dubai Land Department (DLD)</p></div>
          <div className="logo-item"><img src={logoDamac} alt="Partner 3" /><p>Damac Properties</p></div>
          <div className="logo-item"><img src={logoNakheel} alt="Partner 4" /><p>Nakheel</p></div>
          <div className="logo-item"><img src={logoEmaar} alt="Partner 5" /><p>Emaar Properties</p></div>
        </div>
        </div>
      </section>

      <Feedback />

      <ContactForm />

    </div>

  );
};

export default HomePage;
