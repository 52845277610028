import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png';
import '../css/styles.css'; 

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className="header">
        <div className="header-container">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="Logo" />
        </Link>
      </div>
      <nav className={`nav ${isMobileMenuOpen ? 'nav-open' : ''}`}>
        <ul>
          <li><Link to="/" onClick={() => setMobileMenuOpen(false)}>Main</Link></li>
          <li><Link to="/properties" onClick={() => setMobileMenuOpen(false)}>Properties</Link></li>
          <li><Link to="/about" onClick={() => setMobileMenuOpen(false)}>About</Link></li>
          <li><Link to="/certifications" onClick={() => setMobileMenuOpen(false)}>Certifications</Link></li>
          <li><Link to="/contacts" onClick={() => setMobileMenuOpen(false)}>Contacts</Link></li>
        </ul>
      </nav>
      <div className="language-switcher">
        <span className="language">EN</span>
        <span className="language">CZ</span>
      </div>
      <button className="mobile-menu-button" onClick={toggleMobileMenu}>
        ☰
      </button>
      </div>
    </header>
  );
};

export default Header;
