import React from 'react';

const GalleryPage = () => {
  return (
    <div className="gallery-page">
      <h2>Project Gallery</h2>
      {/* Add gallery images and details */}
    </div>
  );
};

export default GalleryPage;
