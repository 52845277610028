import React from 'react';
import '../css/styles.css';
import img1 from '../assets/cips_logo.jpg'
import img2 from '../assets/abr.jpg';
import img3 from '../assets/logo_clhms.png';
import img4 from '../assets/908b581c1aaff10af35d0d74ad5f5641.jpg';
import img5 from '../assets/leed-certification-logo.png';

export const certificationsData = [
  {
    id: 1,
    image: img1,
    title: 'Certified International Property Specialist (CIPS)',
    description: 'This certification demonstrates expertise in the international real estate market, enabling professionals to assist clients globally in buying and selling properties.'
  },
  {
    id: 2,
    image: img2,
    title: 'Accredited Buyer’s Representative (ABR)',
    description: 'This designation signifies proficiency in representing property buyers, ensuring they receive the best guidance during the purchasing process.'
  },
  {
    id: 3,
    image: img3,
    title: 'Luxury Home Marketing Specialist (CLHMS)',
    description: 'Awarded for excellence in luxury property transactions, this certification highlights expertise in marketing and selling high-end homes.'
  },
  {
    id: 4,
    image: img4,
    title: 'Real Estate Negotiation Expert (RENE)',
    description: 'Focused on negotiation skills, this certification equips professionals to advocate for their clients in complex property deals, ensuring optimal outcomes.'
  },
  {
    id: 5,
    image: img5,
    title: 'Green Building Certification (LEED)',
    description: 'This certification shows a deep understanding of sustainable building practices, helping clients make environmentally conscious property decisions.'
  }
];


const CertificationsPage = () => {
  return (
    <div className="certifications-page">
        <div className='certifications-page-container'>
      <h2>Our Certifications</h2>
      <div className="certifications-grid">
        {certificationsData.map((cert) => (
          <div key={cert.id} className="certification-card">
            <img src={cert.image} alt={cert.title} />
            <h3>{cert.title}</h3>
            <p>{cert.description}</p>
          </div>
        ))}
      </div>
      </div>
    </div>
  );
};

export default CertificationsPage;
