import React from 'react';
import '../css/styles.css';
import logo from '../assets/logo.png';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
        <img src={logo} alt="Logo" />
        </div>
        <div className="footer-info">
          <h3>Information</h3>
          <ul>
            <li><a href="/">Main</a></li>
            <li><a href="/properties">Properties</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="/certifications">Certifications</a></li>
            <li><a href="/contacts">Contacts</a></li>
          </ul>
        </div>
        <div className="footer-contacts">
          <h3>Contacts</h3>
          <p><i className="fas fa-map-marker-alt"></i> Real Estate Dubai, 1234 Sheikh Zayed Road, Dubai, UAE</p>
          <p><i className="fas fa-phone-alt"></i> 512.333.2222</p>
          <p><i className="fas fa-envelope"></i> sampleemail@gmail.com</p>
        </div>
        <div className="footer-social">
          <h3>Social Media</h3>
          <div className="social-icons">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
            <a href="https://pinterest.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-pinterest"></i></a>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
      <p>© 2022 All Rights Reserved | Created by <a href="https://www.graphiqstudio.eu" target="_blank" rel="noopener noreferrer">graphiqstudio.eu</a></p>
      </div>
    </footer>
  );
};

export default Footer;
