import Property1 from '../assets/house-1836070_1280.jpg';
import Property2 from '../assets/house-2418106_1280.jpg';
import Property3 from '../assets/house-2483336_1280.jpg';
import Property4 from '../assets/large-home-389271_1280.jpg';
import Property5 from '../assets/architecture-5999913_1280.jpg';

import Features1 from '../assets/DUBAI_Palm.jpg';
import Features2 from '../assets/DUBAI_Downtown.jpg';
import Features3 from '../assets/DUBAI_Marina.jpg';



export const propertiesData = [
  {
      id: 1,
      name: 'location1',
      image: Property1,
      title: 'LUXURY VILLA IN PALM JUMEIRAH',
      short_desc: 'Exquisite villa in Palm Jumeirah with beachfront access.',
      description: 'This stunning beachfront villa offers 5 bedrooms, private beach access, and a panoramic view of the Arabian Gulf. Located in the prestigious Palm Jumeirah, the property is designed for luxury living, complete with a private pool, modern finishes, and close proximity to world-class amenities.',
      location: 'Palm Jumeirah, Dubai',
      price: 'AED 25,000,000',
      link: '/properties/location1'
  },
  {
      id: 2,
      name: 'location2',
      image: Property2,
      title: 'MODERN APARTMENT IN DUBAI MARINA',
      short_desc: 'Stylish apartment in Dubai Marina with marina views.',
      description: 'This modern 3-bedroom apartment located in Dubai Marina offers floor-to-ceiling windows, a fully equipped kitchen, and spectacular marina views. The property is ideal for those seeking a vibrant waterfront lifestyle, with easy access to restaurants, cafes, and luxury retail outlets.',
      location: 'Dubai Marina, Dubai',
      price: 'AED 5,000,000',
      link: '/properties/location2'
  },
  {
      id: 3,
      name: 'location3',
      image: Property3,
      title: 'SPACIOUS VILLA IN ARABIAN RANCHES',
      short_desc: 'Expansive family villa in the peaceful Arabian Ranches community.',
      description: 'This 4-bedroom villa in Arabian Ranches offers ample space for families, featuring a large garden, modern kitchen, and open-plan living areas. The property is located in a family-friendly community with parks, schools, and a golf course nearby, perfect for those looking for suburban peace.',
      location: 'Arabian Ranches, Dubai',
      price: 'AED 8,500,000',
      link: '/properties/location3'
  },
  {
      id: 4,
      name: 'location4',
      image: Property4,
      title: 'EXCLUSIVE MANSION IN EMIRATES HILLS',
      short_desc: 'Luxury mansion with golf course views in Emirates Hills.',
      description: 'Located in the prestigious Emirates Hills, this mansion offers unparalleled luxury with 7 bedrooms, a home theater, private pool, and stunning golf course views. The property’s expansive design and prime location make it one of the most exclusive homes in Dubai.',
      location: 'Emirates Hills, Dubai',
      price: 'AED 40,000,000',
      link: '/properties/location4'
  },
  {
      id: 5,
      name: 'location5',
      image: Property5,
      title: 'PENTHOUSE IN DOWNTOWN DUBAI',
      short_desc: 'Penthouse in Downtown Dubai with views of Burj Khalifa.',
      description: 'This luxurious 4-bedroom penthouse is located in the heart of Downtown Dubai. It features a spacious terrace with panoramic views of Burj Khalifa and The Dubai Fountain, an open-plan kitchen, and premium finishes. Perfect for those who want to live in the center of Dubai’s vibrant lifestyle.',
      location: 'Downtown Dubai, Dubai',
      price: 'AED 20,000,000',
      link: '/properties/location5'
  }
];


export const featuresData = [
    {
        id: 1,
        name: 'feature1',
        image: Features1,
        title: 'THE PALM JUMEIRAH',
        description: 'This is the biggest land reclamation project on earth, a giant archipelago designed in the shape of a palm tree whose fourteen fronds are fringed with beach-side houses and villas. The spinal ‘trunk’ has apartment buildings, shops, hotels, marinas, spas and its very own monorail. To build the Palm some five million boulders were loaded onto giant barges and sunk to form an artificial island surrounded by an eleven kilometre breakwater rising two metres out of the sea. No concrete, no steel, just rocks and sand were used to create this engineering masterpiece. It has been said that if the rocks had been used to build a two metre high wall, that wall could wrap around the world twice over.',
        highlight: 'Offers a resort lifestyle, with beaches, restaurants, cafes, and some of the world’s best hotels on your doorstep. Direct beach access and the outdoor fitness facilities, running tracks, promenade with restaurants and beach clubs. Home to a wide collection of beachfront apartments, villas and the iconic hotel resort, Atlantis The Palm The largest man-made island in the world',
        location: 'THE PALM JUMEIRAH',
        link: '/features/feature1'
    },
    {
        id: 2,
        name: 'feature2',
        image: Features2,
        title: 'DUBAI MARINA',
        description: 'At the heart of ‘New Dubai’ the Marina is more than 3 km long and is encircled by a broad, landscaped promenade, a favourite among strollers, walkers, joggers and cyclists. Along the waterfront are numerous restaurants and cafes where you can sit at sunset and watch the surrounding skyscrapers begin to light up to create a spectacular backdrop as the evening dinner cruises glide out to sea. Ninety percent of the 200 or so skyscrapers surrounding the Marina are residential blocks including Princess Tower, the tallest residential building in the world. Add to that the 40 Jumeirah Beach Residence towers catering for 15,000 occupants and it is not hard to understand why the Dubai Marina district is the most densely populated neighbourhood in the country and such a vibrant community.',
        highlight:'Offers luxurious waterfront lifestyle, is home to a shopping mall, promenade and many popular restaurants and hotels. Community is known as the ‘tallest block in the world’ and many of its buildings are part of Dubai’s iconic skyline with including the distinctive Cayan Tower. Popular among tourists for cruise tours, beach activities and retail therapy',
        location: 'DUBAI MARINA',
          link: '/features/feature2'
    },
    {
        id: 3,
        name: 'feature3',
        image: Features3,
        title: 'DOWNTOWN DUBAI',
        description: 'Downtown Dubai is a huge residential and commercial development in the north of Dubai. It is home to some of Dubai’s best known landmarks including Burj Khalifa, the Dubai Mall, the Dubai Fountain and Dubai Opera. Burj Khalifa, the world’s tallest building, contains a hotel, office space and apartments. Surrounding areas have low-rise villas and town houses as well as residential towers. The metro connects with Downtown and the Dubai Mall whose 1,200 retail outlets, aquarium and ice rink draw shoppers and leisure seekers from all over the country.',
        highlight:'Centrally Located in the heart of Dubai. Home to Dubai’s most popular attractions such as Burj Khalifa, Dubai Mall, Dubai Opera and the Dubai Fountain. Vibrant lifestyle filled with events & activities, restaurants and cafes with a breathtaking view of the tallest building in the world',
        location: 'DOWNTOWN DUBAI',
        link: '/features/feature3'
    },

];
