import React from 'react';
import { useParams } from 'react-router-dom';
import { featuresData } from './Data'; // Ensure this path is correct

const FeatureDetail = () => {
  const { name } = useParams(); // Get the feature name from the URL
  const feature = featuresData.find((f) => f.name === name); // Find the matching feature

  if (!feature) {
    return <div>Feature not found</div>; // Show this message if no matching feature
  }

  return (
    <div className="feature-detail">
    <h1 className="feature-text">{feature.title}</h1>
    <img src={feature.image} alt={feature.title} />
     
      <div className="feature-info">
        <p><strong>ABOUT THE LOCATION:</strong> {feature.description}</p>
        <p><strong>LOCATION:</strong> {feature.location}</p>
        <p><strong>KEY HIGHLIGHTS:</strong> {feature.highlight}</p>
      </div>
    </div>
  );
};

export default FeatureDetail;
